<template>
	<div class="commodityDetails">
		<div class="inner" v-if="params.sku.length>0">
			<topLabel></topLabel>
			<div class="info">
				<div class="left">
					<imgListShow :imgList="imgList" v-if="imgList.length>0"></imgListShow>
				</div>
				<div class="right">
					<rightInfo :data='params' :type="type"></rightInfo>
				</div>
			</div>
			<div class="tj">
				<div class="title">
					<div class="">
						{{i18n('141')}}
					</div>
				</div>
				<div class="list">
					<div :class="index>4?'none':''" v-for="(item,index) in recommendData" :key="index">
						<commodity :data='item' v-if="index<5"></commodity>
					</div>
				</div>
			</div>
			<div class="title">
				<div class="">
					Product Details
				</div>
			</div>
			<div class="zz">
				{{lang=='zh'?'我们的宗旨':'Our Mission'}}
			</div>
			<div class="sm">
				<div class="left">
					<div v-if="lang=='zh'">产品最全</div>
					<div v-if="lang=='en'">Wide Range of Products</div>
					<div v-if="lang=='vie'">Các loại sản phẩm</div>
					<div v-if="lang=='zh'">野菜为您提供一站式亚洲商品购物体验，野菜专属骑手配送，上野菜啥都有！</div>
					<div v-if="lang=='en'">We provide the widest range of products from fresh produce to grocery, from
						bakery to milk tea,from household cleaners to gadgets – the only one-stop-shop you need! </div>
					<div v-if="lang=='vie'">Chúng tôi cung cấp các loại sản phẩm, từ ​​nông sản tươi sống đến tạp hóa,
						từ bánh mì đến trà sữa, từ chất tẩy rửa gia dụng đến đồ dùng tiện ích - Cửa hàng duy nhất bạn
						cần.</div>
				</div>
				<img src="@/assets/fresh.png" alt="" style="width: 122px;
height: 90px;margin-right: 123px;">
			</div>
			<div class="sm">
				<div class="left">
					<div v-if="lang=='zh'">品质优先</div>
					<div v-if="lang=='en'">Quality Always Comes First</div>
					<div v-if="lang=='vie'">Chất lượng luôn đi đầu</div>
					<div v-if="lang=='zh'">野菜严选来自世界各地的优质产品，坚持合理优惠的价格，提供最好的服务和品质！</div>
					<div v-if="lang=='en'">We strive our best to offer the best quality products at reasonable prices.
						We never compromise on quality! </div>
					<div v-if="lang=='vie'">Chúng tôi cố luôn cố gắng để cung cấp các sản phẩm chất lượng tốt nhất với
						giá cả hợp lý. Chúng tôi luôn đi đầu về chất lượng!</div>
				</div>
				<img src="@/assets/kc.png" alt="" style="width: 164px;
height: 87px;margin-right: 104px;">
			</div>
			<div class="sm">
				<div class="left">
					<div v-if="lang=='zh'">极速配送</div>
					<div v-if="lang=='en'">Express Delivery to Your Doorstep </div>
					<div v-if="lang=='vie'">Giao hàng nhanh đến tận cửa nhà của bạn </div>
					<div v-if="lang=='zh'">我们自营物流团队，极速配送，最快30分钟到达，安全可信！</div>
					<div v-if="lang=='en'">Our world class logistic team delivers your order right to your doorstep, as
						fast as 30 minutes. No more hassle to do shopping under the rain or heat!</div>
					<div v-if="lang=='vie'">
						Đội ngũ hậu cần đẳng cấp của chúng tôi giao đơn đặt hàng đến ngay của nhà bạn, nhanh nhất là 30
						phút. Không còn rắc rối để mua sắm dưới mưa hoặc dưới thời tiết nắng nóng.</div>
				</div>
				<img src="@/assets/ser.png" alt="" style="width: 128px;
height: 128px;margin-right: 112px;">
			</div>
			<div class="jgsm">
				<div v-if="lang=='zh'">价格说明</div>
				<div v-if="lang=='en'">TermsAndConditions</div>
				<div v-if="lang=='vie'">TermsAndConditions</div>
				<div v-if="lang=='zh'">
					*划线价格：并非菲律宾相关法律法规中所指商品“原价”的含义，仅为商品的指导价、正品零售价或野菜生鲜曾经向用户展示的销售价，仅供您参考，并非原价。未划线价格：该价格是指商品在野菜生鲜对用户展示的销售价，具体的成交价格可能会因使用优惠券、积分等而发生变化，最终以订单结算页价格为准。<br>
					*此说明仅当出现价格比较适用。<br>
					*因生鲜产品特性及运营情况差异等原因，不同区域的站点所配送的商品价格可能会有不同。<br>
				</div>
				<div v-if="lang=='en'">
					Slashed Price: it does not refer to “Original Price” in the law of the Philippines. “Slashed Price”
					refers to the recommended retail price (RRP), list price, or prices that Yeah Fresh offered to
					consumers.<br>
					Non-slashed Price: it refers to the price that Yeah Fresh offers to consumers. The actual selling
					price may vary, depends on the use of coupons, redeemed points and others. The actual selling price
					is shown on the checkout page.<br>
					* Apply to all prices that are compared to other prices<br>
					* The selling prices may vary depending on the locations because of a series of operational factors.
				</div>
				<div v-if="lang=='vie'">
					Slashed Price: it does not refer to “Original Price” in the law of the Philippines. “Slashed Price”
					refers to the recommended retail price (RRP), list price, or prices that Yeah Fresh offered to
					consumers.<br>
					Non-slashed Price: it refers to the price that Yeah Fresh offers to consumers. The actual selling
					price may vary, depends on the use of coupons, redeemed points and others. The actual selling price
					is shown on the checkout page.<br>
					* Apply to all prices that are compared to other prices<br>
					* The selling prices may vary depending on the locations because of a series of operational factors.
				</div>
			</div>
		</div>
		<foot v-if="params.sku.length>0"></foot>
	</div>
</template>

<script>
	import {
		commodityInfo,
		detailRecommend
	} from "@/api/commodity.js"

	import topLabel from "./components/topLabel.vue"
	import imgListShow from "./components/imgListShow.vue"
	import rightInfo from "./components/rightInfo.vue"
	import commodity from "@/components/commodity.vue"
	import foot from "@/components/topBot/footer.vue"
	export default {
		name: "commodityDetails",
		components: {
			topLabel,
			imgListShow,
			rightInfo,
			commodity,
			foot
		},
		data() {
			return {
				params: {
					base: {
						id: false
					},
					sku: []
				},
				category_id: 0,
				recommendData: [],
				imgList: [],
				type: 1, //1正常商品  2没有库存   3下架商品
			}
		},
		methods: {
			async commodityInfo() {
				let {
					data
				} = await commodityInfo({
					id: this.params.base.id
				})
				this.category_id = Number(data.base.category_id.split(',')[0])
				if (this.$store.state.user.categoryList) {
					this.$store.commit("user/setSeleCategory", {
						childrenId: this.category_id
					})
				} else {
					setTimeout(() => {
						this.$store.commit("user/setSeleCategory", {
							childrenId: this.category_id
						})
					}, 1000)
				}

				this.params = data
				if (data.base.status != 2) {
					this.type = 3
				} else if (this.isSx()) {
					this.type = 2
				} else {
					this.type = 1
				}
				this.imgList.push(this.params.base.cover)
				if (this.params.images) {
					this.params.images.map(item => {
						this.imgList.push(item.url)
					})
				}
				return true
			},
			async detailRecommend() {
				let {
					data
				} = await detailRecommend({
					category_id: this.category_id
				})
				this.recommendData = data
			},
			isSx() {
				if (this.params.base.stock <= 0) {
					return true
				} else {
					let stock = 0
					if (this.params) {
						this.params.sku.map(item => {
							if (item.stock_type == 0) {
								stock = stock + parseInt(this.params.base.stock / item.quantity)
							} else {
								stock = stock + parseInt(this.params.base.stock / (item.quantity * this.params
									.unit_info.proportion))
							}
						})
					}
					if (stock == 0) {
						return true
					} else {
						return false
					}
				}
			},
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		async created() {
			this.params.base.id = Number(this.$route.query.id)
			await this.commodityInfo()
			this.detailRecommend()
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.jgsm div:nth-child(1) {
		font-family: PingFangSC-Medium;
		font-size: 24px;
		color: #B7B7B7;
		line-height: 24px;
		font-weight: 500;
		margin-top: 33px;
		margin-bottom: 18px;
	}

	.jgsm div:nth-child(2) {
		font-family: PingFangSC-Regular;
		font-size: 20px;
		color: #1D2129;
		line-height: 30px;
		font-weight: 400;
		padding-bottom: 100px;
	}

	.sm {
		padding: 41px 0 70px 0;
		border-bottom: 1px solid rgba(232, 232, 232, 1);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.sm .left div:nth-child(1) {
		font-family: PingFangSC-Medium;
		font-size: 24px;
		color: #1D2129;
		line-height: 24px;
		font-weight: 500;
		margin-bottom: 18px;
	}

	.sm .left div:nth-child(2) {
		font-family: PingFangSC-Regular;
		font-size: 20px;
		color: #1D2129;
		line-height: 30px;
		font-weight: 400;
	}

	.zz {
		width: 1200px;
		height: 64px;
		border: 1px solid rgba(86, 171, 123, 0.1);
		background: url(../../assets/xqBg.png)no-repeat;
		background-size: 228px 64px;
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #1D2129;
		font-weight: 500;
		line-height: 64px;
		padding-left: 76px;
		box-sizing: border-box;
	}

	.tj .list {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.none {
		display: none;
	}

	.title {
		width: 1200px;
		height: 42px;
		background: #F3F3F3;
		border: 1px solid rgba(86, 171, 123, 0.1);
		margin-bottom: 24px;
	}

	.title div {
		padding: 0 22px;
		height: 44px;
		background: #56AB7B;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 400;
		line-height: 42px;
		display: inline-block;
		margin-top: -1px;
	}

	.tj {
		margin-bottom: 32px;
	}

	.info {
		margin-bottom: 48px;
		display: flex;
	}

	.info .left {
		margin-right: 32px;
	}

	.info .right {
		flex: 1;
	}

	.commodityDetails {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
	}

	.inner {
		width: 1200px;
		margin: 0 auto;
	}
</style>
