<template>
	<div class="rightInfo">
		<div class="name">
			<span v-if="data.base.delivery_modeint==2">{{i18n('142')}}</span>{{lang=='zh'?data.base.zh_name:lang=='en'?data.base.en_name:data.base.vi_name}}
		</div>
		<div class="dis over">
			{{lang=='zh'?data.base.zh_selling_point:lang=='en'?data.base.en_selling_point:data.base.vi_selling_point}}
		</div>
		<div class="bot" v-if="data.skuAc">
			<div class="text">
				{{i18n('76')}}
			</div>
			<div style="display: flex;align-items: baseline;">
				<div class="dw">
					₱
				</div>
				<div class="number">
					{{data.skuAc.price}}
				</div>
				<div class="gg">
					/{{lang=='zh'?data.skuAc.zh_name:lang=='en'?data.skuAc.en_name:data.skuAc.vi_name}}
				</div>
			</div>
		</div>
		<div class="ggList" v-if="data.skuAc">
			<div class="left">
				{{i18n('77')}}
			</div>
			<div class="right">
				<div :class="item.id==data.skuAc.id?'item itemAc':'item'" v-for="(item,index) in data.sku" :key='index'
					@click="skuAcChange(index)">
					{{lang=='zh'?item.zh_name:lang=='en'?item.en_name:item.vi_name}}
				</div>
			</div>
		</div>
		<div class="sl">
			<div class="left">
				{{i18n('78')}}
			</div>
			<div class="jjcz">
				<div class="jian" @click="numberChange(2)">
					<img src="@/assets/jian.png" alt="" mode="widthFix">
				</div>
				<div class="num">
					<input type="number" v-model="Count" @change="numberChange(3)">
				</div>
				<div class="jia" @click="numberChange(1)">
					<img src="@/assets/jia.png" alt="" mode="widthFix">
				</div>
			</div>
			<div class="add" @click="sumb" v-if="type==1">
				{{i18n('79')}}
			</div>
			<div class="add dhts" @click="dhtz" v-else>
				{{i18n('143')}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "rightInfo",
		props:['data','type'],
		data() {
			return {
				stock: [],
				Count: 1
			}
		},
		methods: {
			dhtz(){
				this.$message.success(this.i18n('144'))
			},
			async sumb() {
				let params = JSON.parse(JSON.stringify(this.data))
				params.Count = this.Count
				await this.$store.dispatch("cart/addCart", params)
				this.$message.success(this.i18n('145'))
			},
			numberChange(type) {
				if (type == 1) {
					//加
					let num = this.Count + 1
					if (num > this.purchase_limit) {
						this.$message.error(this.i18n('80') + this.purchase_limit)
					} else if (this.data.skuAc.stock >= num) {
						this.Count = num
					} else {
						this.$message.error(this.i18n('81'))
					}
				} else if (type == 2) {
					if (this.Count > 1) {
						this.Count--
					}
				} else if (type == 3) {
					if (this.Count > this.purchase_limit) {
						this.$message.error(this.i18n('80') + this.purchase_limit)
						this.Count = this.purchase_limit
					} else if (this.data.skuAc.stock < this.Count) {
						this.$message.error(this.i18n('81'))
						this.Count = this.data.skuAc.stock
					}
				}
			},
			skuAcChange(index) {
				if (this.stock[index] <= 0 && this.type==1) {
					this.$message.error(this.i18n('81'))
					return false
				}
				this.data.skuAc = this.data.sku[index]
				let spData = this.$store.state.cart.commodity
				let zd = false
				spData.map(item => {
					if (item.skuAc.id == this.data.skuAc.id) {
						zd = true
						if (this.stock[index] < item.Count) {
							this.Count = this.stock[index]
						} else {
							this.Count = item.Count
						}
			
					}
				})
				if (!zd) {
					this.Count = 1
				}
				this.$forceUpdate()
			},
			async getStock(data) {
				let stock = []
				data.sku.map((item, index) => {
					if (item.stock_type == 0) {
						stock.push(parseInt(data.base.stock / item.quantity))
						data.sku[index].stock = (parseInt(data.base.stock / item.quantity))
					} else {
						stock.push(parseInt(data.base.stock / (item.quantity * data.unit_info.proportion)))
						data.sku[index].stock = (parseInt(data.base.stock / (item.quantity * data.unit_info
							.proportion)))
					}
				})
			
				return stock
			},
		},
		async mounted() {
			let stock = await this.getStock(this.data)
			this.stock = stock
			this.Count = 1
			this.data.skuAc = false
			let indexs = 0
			this.stock.map((item, index) => {
				if (item > 0 && !this.data.skuAc) {
					this.data.skuAc = this.data.sku[index]
					indexs=index
				}
			})
			if(!this.data.skuAc){
				console.log(1000)
				this.data.skuAc = this.data.sku[0]
				console.log(this.data.skuAc)
			}
			this.skuAcChange(indexs)
		},
		computed: {
			purchase_limit() {
				let purchase_limit = 10000000000
				if (this.data) {
					if (this.data.base.purchase_limit > 0) {
						purchase_limit = this.data.base.purchase_limit
					}
				}
				return purchase_limit
			},
			lang() {
				return this.$store.state.i18n.lang
			}
		}
	};
</script>

<style scoped>
	.rightInfo{
		width: 100%;
	}
	.name{
		font-family: PingFangSC-Regular;
		font-size: 20px;
		color: #1D2129;
		font-weight: 400;
		display: flex;
		align-items: center;
		margin-bottom: 11px;
	}
	.name span{
		height: 28px;
		background: #F23725;
		border-radius: 4px;
		margin-right: 6px;
		padding: 0 8px;
		line-height: 28px;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #FFFFFF;
		font-weight: 400;
	}
	.dis{
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #56AB7B;
		font-weight: 400;
		margin-bottom: 13px;
	}
	.bot {
		width: 100%;
		height: 48px;
		background: rgba(86, 171, 123, 0.08);
		display: flex;
		align-items: center;
		padding: 0 10px;
		box-sizing: border-box;
		margin-bottom: 26px;
	}
	
	.bot .text {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		opacity: 0.6;
		font-weight: 400;
		margin-right: 24px;
	}
	
	.bot .dw {
		font-family: Helvetica;
		font-size: 22px;
		color: #F23725;
		font-weight: 400;
	}
	
	.bot .number {
		font-family: PingFangSC-Medium;
		font-size: 30px;
		color: #F23725;
		font-weight: 500;
	}
	
	.bot .gg {
		opacity: 0.5;
		font-family: PingFangSC-Regular;
		font-size: 20px;
		color: #1D2129;
		font-weight: 400;
	}
	.ggList {
		display: flex;
		margin-top: 32px;
		margin-bottom: 57px;
		padding-bottom: 20px;
		border-bottom: 1px dashed rgba(232,232,232,1);
	}
	
	.ggList .left {
		width: 44px;
		opacity: 0.6;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		/* line-height: 32px; */
	}
	
	.ggList .right {
		display: flex;
		flex-wrap: wrap;
	}
	
	.ggList .right .item {
		padding: 0 36px;
		min-width: 120px;
		height: 32px;
		background: #FFFFFF;
		border: 1px solid rgba(183, 183, 183, 1);
		border-radius: 4px;
		text-align: center;
		line-height: 32px;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #1D2129;
		text-align: center;
		font-weight: 400;
		margin-bottom: 12px;
		margin-right: 12px;
		box-sizing: border-box;
		cursor: pointer;
	}
	
	.ggList .right .itemAc {
		border: 1px solid rgba(86, 171, 123, 1);
		color: #56AB7B;
	}
	.sl {
		display: flex;
		margin-bottom: 50rpx;
	}
	
	.sl .left {
		width: 44px;
		opacity: 0.6;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}
	
	.num {
		width: 72px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(86, 171, 123, 0.10);
	}
	
	.num input {
		width: 72px;
		height: 44px;
		background: none;
		border: none;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Regular;
		font-size: 22px;
		color: #1D2129;
		font-weight: 400;
	}
	
	.jian,
	.jia {
		width: 36px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	
	.jian img,
	.jia img {
		width: 16px;
		height: 16px;
		display: block;
	}
	
	.jian {
		border-right: 1px solid rgba(193, 193, 193, 1);
	}
	
	.jia {
		border-left: 1px solid rgba(193, 193, 193, 1);
	}
	
	.jjcz {
		width: 144px;
		height: 44px;
		background: #FFFFFF;
		border: 1px solid rgba(193, 193, 193, 1);
		border-radius: 4px;
		display: flex;
		margin-bottom: 50px;
	}
	.add{
		width: 200px;
		height: 44px;
		background: #56AB7B;
		border-radius: 4px;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F5F5F5;
		font-weight: 400;
		text-align: center;
		line-height: 44px;
		display: block;
		margin-left: 12px;cursor: pointer;
	}
	.dhts{
		background: #F39E4E;
	}
</style>