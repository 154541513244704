<template>
	<div class="imgListShow">
		<div class="top">
			<img :src="seleData" alt="">
		</div>
		<div class="bot">
			<img :src="item" alt="" v-for="(item,index) in imgList" :key='index' :class="item==seleData?'ac':''" @mousemove="seleDataChange(item)">
		</div>
	</div>
</template>

<script>
	export default {
		name: "imgListShow",
		props:['imgList'],
		data() {
			return {
				seleData:false
			}
		},
		mounted() {
			this.seleData = this.imgList[0]
		},
		methods: {
			seleDataChange(data){
				this.seleData = data
			}
		}
	};
</script>

<style scoped>
	.imgListShow .top{
		width: 350px;
		height: 350px;
		border-radius: 8px;
		overflow: hidden;
		margin-bottom: 12px;
	}
	.imgListShow .top img{
		width: 100%;
		height: 100%;
		display: block;
	}
	.imgListShow .bot{
		width: 350px;
		height: 66px;
		display: flex;
		align-items: center;
		overflow-y: scroll;
	}
	.imgListShow .bot img{
		width: 64px;
		height: 64px;
		border-radius: 4px;
		margin-right: 10px;
		display: block;
		border: 1px solid transparent;
		cursor: pointer;
	}
	.imgListShow .bot img.ac{
		border: 1px solid rgba(86,171,123,1);
	}
</style>